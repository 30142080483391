
// Mixins
// ====================================================================
@mixin IntelLight {
    font-family: 'Intel', sans-serif;
    font-weight: 300;
}

@mixin Intel {
    font-family: 'Intel', sans-serif;
    font-weight: normal;
}

@mixin IntelMedium {
    font-family: 'Intel', sans-serif;
    font-weight: 500;
}

@mixin IntelSemiBold {
    font-family: 'Intel', sans-serif;
    font-weight: 600;
}

@mixin IntelBold {
    font-family: 'Intel', sans-serif;
    font-weight: bold;
}

