/*============================================================================
	#Header
==============================================================================*/

.header {
    max-width: 1920px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 88;
    top: 0;

    &__header-right {
        width: 85%;
    }

    &__header-logo {
        width: 21%;
        height: 73px;
        transition: 0.5s;

        img {
            width: 158px;
            height: 36px;
        }

        p {
            font-size: 12px;
            line-height: 24px;
            letter-spacing: 0.2em;
            color: #676767;
            text-align: right;
            margin-top: -5px;
        }
    }


    &__content {
        display: flex;
        width: 1185px;
        justify-content: space-between;
        background-color: #fff;
        border-bottom-right-radius: 100px;
        position: relative;
        padding-left: 32px;

        &:after {
            content: '';
            position: absolute;
            z-index: 1;
            bottom: 0px;
            left: -498px;
            width: 500px;
            height: 73px;
            background: #fff;
        }
    }

    &__header-top {
        display: flex;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;
        background: #EFEDEE;
        margin: 0 auto;
        height: 33px;
        padding-right: 102px;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 40px;
        @media(max-width: 1199px) {
            width: 100%;
        }
    }

    &__header-bottom {
        display: flex;
        justify-content: space-between;
        position: relative;
        height: 40px;
        align-items: center;
        margin: 0 auto;
        padding-right: 95px;
    }
}

.header__header-top {
    border-bottom-right-radius: 40px;
}

.header-top {
    &__email {
        display: flex;
        margin: 0;
        width: 41%;

        a {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #959392;
            letter-spacing: 0.5px;
        }

        .icon-svg {
            width: 24px;
            height: 18px;
            fill: #959392;
            margin-right: 6px;

        }

        &:hover {
            .icon-svg {
                fill: #79726E;
            }
        }
    }

    &__registered {
        width: 230px !important;

        .header-top__currency {
            position: relative;

            &:after {
                position: absolute;
                top: -17px;
                right: -25px;
                content: '';
                width: 1px;
                height: 70px;
                background: rgba(0, 0, 0, 0.5);
            }
        }
    }

    &__go-out {
        position: relative;

        p {
            font-size: 12px;
            line-height: 15px;
            color: #173542;
        }

        a {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: #173542;
            transition: 0.5s;

            &:hover {
                color: #C62506;
            }
        }
    }

    &__login-icon {
        position: absolute;
        top: 8px;
        left: -34px;
        width: 20px;
        height: 25px;
    }

    &__left-side {
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__currency {
        p {
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            color: #173542;
            margin: 0 0 3px;

            &:last-child {
                margin-bottom: 0;
            }

            span {
                font-weight: 400;
            }
        }
    }

    &__login {
        display: flex;
        width: 20%;
        padding-left: 2px;

        a, p {
            font-size: 14px;
            line-height: 17px;
            color: #959392;
            display: block;
            text-decoration: inherit;
            letter-spacing: 0.3px;
            margin-right: 5px;
        }
    }

    &__language {
        width: 5%;
        position: relative;
        z-index: 55;
    }

    &__logo {
        margin: 15px auto 0;
        width: 158px;
        height: 36px;
        transition: 0.5s;
        @media(max-width: 767px) {
            width: 147px;
            height: 30px;
        }
    }

    &__contacts {
        padding-left: 34px;
        width: 37%;
        position: relative;
        display: flex;

        .header-top__contacts-icon {
            width: 15px;
            margin-top: 10px;
            height: 16px;
            position: absolute;
            left: 212px;

            &:hover {
                cursor: pointer;
            }
        }

        .header-top__contacts-list {
            position: absolute;
            top: 26px;
            left: 26px;
            width: 203px;
            height: 30px;
            background: #efedee;
            z-index: 222;
            padding-left: 8px;
            //border-bottom-left-radius: 18px;
            display: none;
        }

        .header-top__contacts-list.open {
            display: block;
        }

        .icon-svg {
            width: 19px;
            height: 21px;
            fill: #959392;
        }

        &:hover {
            .icon-svg {
                fill: #79726E;
            }
        }
    }

    &__right-side {
        display: flex;
        justify-content: space-between;
        width: 250px;
        align-items: center;
        min-height: 49px;
    }

    &__center-block {
        display: flex;
        @media(max-width: 1199px) {
            display: none;
        }
    }

    &__icon-shopping {
        display: none;
    }

    &__icon-burger {
        display: none;
    }

    &__item {
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        margin: 0 25px 0 0;

        a {
            color: #5F5F5F;
            transition: 0.5s;

            &:hover {
                color: #C62506;
            }
        }

        &_language {
            margin: 0;

            a {
                font-size: 12px;
                color: #ABABAB;
                transition: 0.5s;
                margin: 0 9px;

                &:hover {
                    color: #25282E;
                }
            }
        }

        .active {
            a {
                color: #c62506;
            }
        }

        &:last-child {
            content: '';
            margin: 0;
        }
    }
}

.banner__menu .inside-menu::-webkit-scrollbar {
    width: 8px;
}

.banner__menu .inside-menu::-webkit-scrollbar-track {
    background: #E5E8F4;
}

.banner__menu .inside-menu::-webkit-scrollbar-thumb {
    background: rgba(74, 86, 104, 0.4);
}

#header-menu-other .inside-menu {
    min-height: 400px;
    overflow: auto;
}

.t-phone {
    font-size: 14px;
    line-height: 22px;
    color: #959392;
    text-decoration: inherit;
    letter-spacing: 0.5px;
    margin-left: 8px;

    &:hover {
        color: #79726E;
    }
}

.header-top__contacts-list.open .t-phone {
    margin-left: 4px;
}

.cont-schedule {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.18em;
    color: #173542;
}

#header-menu-other.open {
    display: block;
}

.header-up #header-menu-other.open .header-menu__overlay {
    width: 100%;
    height: 100vh;
    background: rgba(229, 232, 244, 0.8);
    position: fixed;
    bottom: 0;
    left: 0;
    -webkit-transition: bottom .3s ease-in-out;
    transition: bottom .3s ease-in-out;
    z-index: -1;
    top: 105px;
    right: 0;
}

.header-down #header-menu-other.open .header-menu__overlay {
    width: 100%;
    height: 100vh;
    background: rgba(229, 232, 244, 0.8);
    position: fixed;
    bottom: 0;
    left: 0;
    -webkit-transition: bottom .3s ease-in-out;
    transition: bottom .3s ease-in-out;
    z-index: -1;
    top: 35px;
    right: 0;
}

.header-menu.open {
    display: block;
}

.mobile-item {
    display: none;
    @media(max-width: 1199px) {
        display: block;
    }
}

.header-bottom {
    &__left-side {
        position: relative;
        z-index: 2;
        display: flex;
        font-size: 14px;
        line-height: 17px;

    }

    &__bottom-item {
        margin: 0;
        padding: 11px 29px;

        &:first-child {
            position: relative;

            a {
                font-size: 14px;
                line-height: 17px;
                color: #292929;
                z-index: 3;
            }

            &:before {
                content: '';
                position: absolute;
                top: 9px;
                left: 20px;
                height: 18px;
                width: 18px;
                background-image: url(/assets/icons/header/catalog.svg);
            }
        }

        &:last-child {
            margin: 0;
        }

        a {
            font-size: 14px;
            line-height: 17px;
            color: #292929;
            letter-spacing: 0.38px;
            position: relative;

            &:before {
                position: absolute;
                bottom: -5px;
                width: 100%;
                background: #292929;
                content: '';
                height: 1px;
                left: 50%;
                transform: translateX(-50%);
                opacity: 0;
                transition: 0.5s;
            }

            &:after {
                position: absolute;
                bottom: -5px;
                width: 27px;
                content: '';
                height: 11px;
                right: -10px;
                border-radius: 50px;
                background-image: url(/assets/icons/header/radius.svg);
                background-repeat: no-repeat;
                background-size: 100%;
                opacity: 0;
                transition: 0.5s;
            }

            @media (max-width: 2500.98px) and (min-width: 1024.98px) {
                &:hover {
                    &:before, &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__right-side {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 2;
        width: 250px;
    }

    .search-button {
        display: flex;

        &__search-style {
            border: 0;
            position: relative;
            height: 37px;
            border-left: 1px solid rgba(21, 45, 68, 0.5);
            border-right: 1px solid rgba(21, 45, 68, 0.5);
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #173542;
            background: inherit;
            padding: 0 15px 0 15px;
            transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
            width: 204px;
            @media(max-width: 1199px) {
                width: 85%;
                background: #fff;
                border: 0;
            }
        }

        button {
            background: inherit;
            width: 46px;
            border-right: 1px solid rgba(21, 45, 68, 0.5);

            img {
                opacity: 0.6;
                margin-top: 5px;
            }

            @media(max-width: 1199px) {
                border-left: 1px solid rgba(21, 45, 68, 0.5);
                width: 15%;
                background: #fff;
                border-right: 0;
            }

        }
    }


    .bottom-side {
        display: none;
        @media(max-width: 1024px) {
            display: block;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            color: #5F5F5F;
            order: 3;
            background-color: #F6F8FF;
            width: 100%;
            text-align: center;
            a {
                font-size: 13px;
                line-height: 18px;
                color: #5F5F5F;
            }
            &__shop-info {
                width: 300px;
                display: flex;
                justify-content: space-between;
                margin: 0 auto;

                &:first-child {
                    padding: 35px 0 5px 0;
                }

                &:last-child {
                    padding: 10px 0 35px 0;
                }
            }
        }
    }


    &__icon {
        margin: 0 35px 0 0;
        position: relative;
        width: 100%;

        a {
            position: relative;
            z-index: 3;
        }

        &:last-child {
            content: '';
            margin: 0;
        }

        span {
            position: absolute;
            left: 20px;
            bottom: 15px;
            border-radius: 50%;
            background-color: #c62506;
            z-index: 3;
            width: 16px;
            height: 16px;

            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: #FFFFFF;
            text-align: center;
            line-height: 16px;
        }
    }

    &__icon-dark {
        display: none;
    }

    &__dark-search-button {
        display: none;
    }
}

.bottom-side-contacts {
    display: none;
}

.header-bottom__left-side .header-bottom__bottom-item .mobile-arrow {
    display: none;
}

.header-bottom__dark-search-button input:active, .header-bottom__dark-search-button input:focus,
.header-bottom .search-button__search-style:active, .header-bottom .search-button__search-style:focus {
    box-shadow: none !important;
}

.header-menu {
    position: fixed;
    overflow: auto;
    width: 258px;
    height: auto;
    max-height: 300px;

}

.header-bottom__bottom-item.header-bottom__bottom-catalog {
    padding: 11px 35px 11px 35px;
}


.header-bottom__bottom-item {
    position: relative;
}

#header-menu-other .header-menu__item-mobile {
    display: none;
}

@media(max-width: 1199px) {
    #header-menu-other .header-menu__item-mobile {
        display: block;
    }
    .header-bottom__bottom-item p {
        padding: 17px 0;
        display: block;
        text-align: center;
        font-size: 20px;
        line-height: 24px;
        color: #292929;
        letter-spacing: 0.38px;
        position: relative;
    }
    .header-bottom__bottom-item.header-bottom__bottom-catalog {
        padding: 0;
    }
    .header-menu {
        position: relative;
        z-index: 55555;
        top: 0;
        width: 100%;
        display: block;
        transition: 0.5s;
    }
    .header-top__language {
        margin: 0 auto;
        text-align: center;
        display: block;

        a {
            font-size: 18px;
            line-height: 22px;
            color: rgba(149, 147, 146, 0.51);
            margin: 0 9px;
        }

        .active {
            color: #79726E;
        }
    }
    .header-bottom__bottom-item:first-child a {
        font-size: 20px;
        line-height: 24px;
    }
    .header-top__login {
        width: 193px;
        margin: 0 auto;
        border-bottom: 1px solid #856D65;
    }
    .header-top__login a, .header-top__login p {
        font-size: 16px;
        line-height: 35px;
        color: #959392;
        border-bottom: 13px;
        @media(max-width: 1199px) {
            width: auto;
        }
    }
    .header-top__email {
        margin: 0 auto;
        text-align: center;
        display: block;
    }
    .header-top__language, .header-top__email {
        width: 100%;
    }
    .header-top__email {
        margin-bottom: 39px;
    }
    .header__header-bottom {
        padding-right: 0;
    }
    .header-bottom .search-button__search-style {
        height: 48px;

    }
    .header__header-top {
        padding-right: 0;
    }


    .header-bottom__left-side .header-bottom__bottom-item a.header-menu__link--mobile {
        display: block;
        padding: 21px 30px;
    }
    .header-menu__item.mob-active {
        background: #E6E6E9;

        .header-menu__icon {
            img.hover {
                display: inline-block;
            }

            img.active {
                display: none;
            }
        }

        a {
            color: rgba(23, 53, 66, 0.8);
            font-weight: 600;
        }

        a:before {
            z-index: -1;
        }

    }
    .header-menu__item.mob-active .icon-svg {
        fill: #fff;
    }

    .header-bottom__left-side .header-bottom__bottom-item .mobile-arrow {
        display: block;
        width: 8px;
        height: 16px;
        fill: #D0D0D0;
        position: absolute;
        top: 12px;
        right: 0;
    }
    .bottom-side-contacts__item_language.active a {
        color: #c62506;
    }
    .bottom-side-contacts__item .bottom-side-contacts__item_language:last-child {
        padding-right: 0;

    }
    .bottom-side-contacts {
        order: 1;
        @media(max-width: 1199px) {
            min-height: 320px;
        }
    }
    .header {
        border-bottom: 1px solid #E3E6F0;
        @media(max-width: 1199px) {
            background: #F4F4F4;
            height: 57px;
        }


        &:after {
            display: none;
        }

        &__header-bottom {
            display: none;
            flex-direction: column;
            background-color: #f6f8ff;
            height: 100vh;
            overflow: auto;
            justify-content: flex-start;
            position: absolute;
            top: 55px;
            // top: -600px;
            right: 0;
            width: 100%;
            z-index: 6;
        }
    }
    .header-bottom__left-side {
        margin: 0;
        padding-top: 35px;
        width: 100%;
        background: #f6f8ff;
    }
    .header-bottom__bottom-item {
        max-width: 300px;
        margin: 0 auto;
    }
    .header-top {
        @media(max-width: 1199px) {
            background: #F4F4F4;
            height: 57px;
        }

        &__logo {
            margin: 0;
            transition: 0.5s;
        }

        &__contacts {
            display: block;
            margin: 0 auto 15px;
            width: 100%;
            text-align: center;
            padding: 0;
        }

        &__email {
            margin-top: 25px;
        }

        &__schedule {
            display: none;
        }

        &__button {
            display: none;
        }

        &__right-side {
            display: none;
        }

        &__icon-shopping {
            display: block;
            position: relative;
            width: 26px;
            height: 26px;

            a {
                position: relative;
                z-index: 3;
            }

            span {
                position: absolute;
                left: 20px;
                bottom: 15px;
                border-radius: 50%;
                background-color: #c62506;
                z-index: 3;
                width: 16px;
                height: 16px;

                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                color: #FFFFFF;
                text-align: center;
                line-height: 16px;
            }

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 68px;
                background-color: #E3E6F0;
                left: -20px;
            }
        }

        &__icon-burger {
            display: block;
            position: relative;
            height: 78px;
            width: 35px;
            cursor: pointer;
            order: 2;

            span {
                transition: all 0.3s ease 0s;

                &:first-child {
                    position: absolute;
                    top: 31px;
                    left: 0;
                    height: 2px;
                    width: 33px;
                    background-color: #4A5668;
                    border-radius: 1px;
                }

                &:last-child {
                    position: absolute;
                    top: 44px;
                    left: 0;
                    height: 2px;
                    width: 33px;
                    background-color: #4A5668;
                    border-radius: 1px;
                }


            }

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 68px;
                background-color: #E3E6F0;
                right: -20px;
            }
        }

        .icon-burger-active {
            span {
                &:first-child {
                    width: 100%;
                    top: 36px;
                    transform: rotate(45deg);
                    background: #959392;
                }

                &:last-child {
                    bottom: 9px;
                    transform: rotate(-45deg);
                    top: 36px;
                    width: 100%;
                    background: #959392;
                }

                &:nth-child(2) {
                    display: none;
                }
            }
        }
    }

    .header-bottom {
        &__left-side {
            flex-direction: column;
            order: 2;
            position: relative;
            border-bottom: 1px solid #E3E6F0;
        }

        &__bottom-item {
            width: 300px;
            margin: 0 auto 30px auto;

            a {
                font-size: 20px;
                line-height: 24px;
                color: #292929;
            }

            &:last-child {
                margin: 0 auto 35px auto;
            }
        }

        &__right-side {
            order: 1;
            width: 100%;
            justify-content: center;
            border-bottom: 1px solid #E3E6F0;
            background: #F6F8FF;
            min-height: 50px;
        }

        &__icon {
            display: none;
        }

        &__icon-dark {
            display: block;
            position: relative;

            a {
                position: relative;
                z-index: 3;
            }

            span {
                position: absolute;
                left: 20px;
                bottom: 15px;
                border-radius: 50%;
                background-color: #c62506;
                z-index: 3;
                width: 16px;
                height: 16px;

                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                color: #FFFFFF;
                text-align: center;
                line-height: 16px;
            }
        }

        &__dark-search-button {
            display: block;
            position: relative;
            margin: 25px 40px 25px 0;

            a {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 3;
            }
        }

        &__dark-search-style {
            right: 0;
            border-radius: 16px;
            height: 30px;
            background-color: #F6F8FF;
            border: 1px solid #E3E6F0;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #5f5f5f;
            padding: 0 15px 0 13px;
            width: 275px;
            position: relative;

        }
    }

    .bottom-side-contacts {
        display: flex;
        padding: 35px 0 100px 0;
        background-color: white;
        width: 100%;
        justify-content: center;

        &__column {
        }

        &__phone {
        }

        &__button {
            padding: 25px 0 0 0;
        }

        &__schedule {
            padding: 7px 0 0 0;
        }

        &__item {
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            display: flex;
            justify-content: flex-end;
            padding: 15px 0 0 0;

            &_language {
                padding: 18px 30px 0 0;

                a {
                    color: #ABABAB;

                    &:hover {
                        color: #c62506;
                    }
                }

                &:first-child {
                    padding: 18px 30px 0 0;
                }
            }
        }
    }
}

@media(max-width: 375px) {
    .header-bottom {
        &__dark-search-button {
            margin: 25px 20px 25px 0;
        }

        &__dark-search-style {
            width: 240px;
        }
    }
}

.header-bottom__bottom-item #header-menu-other a:before, .header-bottom__bottom-item #header-menu-other a:after {
    display: none;
}

/* header-menu=================== */
.header-menu {
    display: none;
    position: absolute;
    left: 12px;
    top: 33px;
    background-color: #fff;

    &__icon {
        width: 20px;
        height: 20px;
        margin-right: 28px;

        .icon-svg {
            width: 20px;
            height: 20px;
            fill: rgba(0, 0, 0, 1);
        }

    }

    &__arrow {
        width: 8px;
        height: 16px;
        right: 20px;
        position: absolute;
        margin-top: 2px;

        .icon-svg {
            width: 8px;
            height: 16px;
            fill: #530F0F;
        }
    }

    &__item {

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: rgba(23, 53, 66, 0.8);
        padding: 12px 0 14px 33px;
        cursor: pointer;
        display: flex;
        @media (max-width: 2500.98px) and (min-width: 1024.98px) {
            &:hover {
                a {
                    &:before {
                        content: '';
                        display: none;
                    }
                }

                .header-menu__icon {
                    .icon-svg {
                        fill: #fff;
                    }
                }
            }
        }

        &:first-child {
            background: #fff;

            a {
                color: #c62506;
            }

            .icon-svg {
                fill: #c62506;
            }

            @media (max-width: 2500.98px) and (min-width: 1024.98px) {
                &:hover {
                    background-color: #c62506;
                    color: #fff;
                }
            }

            a:before {
                display: none;
            }
        }

        &:last-child {
            a:before {
                display: none;
            }
        }

        @media (max-width: 2500.98px) and (min-width: 1024.98px) {
            &:hover {
                .header-menu__arrow .icon-svg {
                    //fill: #fff;
                }
            }
        }
    }

    .akcyi {
        .header-menu__icon {
            .icon-svg {
                fill: #530F0F;
            }
        }
    }

    a {
        position: relative;
    }

    .inside-menu {
        top: 0;
        padding: 0 20px;
    }

}

.header-bottom__bottom-item #header-menu-other a {
    font-weight: 500;
}

.active-style {
    display: block;
    width: 355px;
}

.header-bottom .search-button__search-style.active-style {
    display: block;
    width: 200px;
}

.header-menu a, .inside-menu__item a {
    font-size: 14px;
    line-height: 17px;
    color: #25282E;
    transition: 0.1s;
}

@media (max-width: 2500.98px) and (min-width: 1024.98px) {
    .header-menu__item:hover a {
        color: #856D65;
    }
    .header-menu__item:hover .inside-menu a {
        font-weight: 400 !important;
    }


    .inside-menu .inside-menu__item:hover {
        background: #E8EBEC;
    }
}

.header-menu__item .inside-menu__item a {
    color: #25282E;
}

.header-menu__item:hover .inside-menu__link-arrow {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #c62506;
}

.header-menu__item:hover .inside-menu a.inside-menu__link-arrow {
    font-weight: 500 !important;
}


/* ============================================== */

/* inside menu=================================== */

.inside-menu {
    position: absolute;
    left: 321px;
    display: none;
    width: 470px;
    height: 100%;
    background-color: #fff;
    min-height: 537px;

    &__title {

        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: #173542;
        padding: 13px 0 8px 25px;
        margin: 15px 0;
        position: relative;
        border-bottom: 1px solid #E3E6F0;
    }

    &__item {

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        padding: 8px 0 8px 50px;
        color: #173542;
        opacity: 0.8;
        position: relative;

        a {
            color: #173542;
            opacity: 0.8;
            font-weight: 400;
        }

        &:hover {
            a {
                color: #856D65;
            }
        }

        &:before {
            content: '';
            position: absolute;
            left: 27px;
            top: 50%;
            transform: translateY(-50%);
            background: #173542;
            border-radius: 50%;
            width: 6px;
            height: 6px;
        }

    }

    &__red-arrow {
        position: relative;
        margin: 25px 0 0 195px;

        &:before {
            content: '';
            position: absolute;
            background-image: url(/assets/icons/red-long-arrow.svg);
            width: 59px;
            height: 14px;
            top: 15px;
            transform: translateY(-50%);
            left: 170px;
            transition: 0.5s;
        }

        &:hover {
            &:before {
                left: 180px;
            }

        }
    }

    &__link-arrow {

        font-style: normal;
        font-weight: 500 !important;
        font-size: 14px;
        line-height: 17px;
        text-align: right;
        color: #c62506;
    }
}

/*============================================================================
	HEADER
==============================================================================*/
.header-down .header__header-top {
    margin-top: -35px;
}

.header-down .header__header-logo img {
    width: 87px;
    height: 20px;
    transition: 0.5s;
    margin: 0 0 0 32px;
}

.header-down .header-top__logo {
    margin: 5px auto 0;
    width: 120px;
    height: 20px;
    transition: 0.5s;
}

.header-down .header__header-logo {
    height: 40px;
}

.header-down .header__header-logo p {
    font-size: 9px;
    line-height: 16px;
}


.header__header-top {
    -webkit-transition: margin .5s ease;
    -o-transition: margin .5s ease;
    transition: margin .5s ease;
}

.header-up .header__header-top {
    margin-top: 0;
}

.search-button__search-styl.active-style:focus::-ms-clear {
    width: 0;
    height: 0;
}

.search-button__search-styl.active-style:focus::-ms-clear {
    display: none;
}

::-ms-reveal {
    display: none;
}

::-ms-clear {
    display: none;
}

input[type=search]::-ms-clear {
    display: none;
}

/*============================================================================
	HEADER
==============================================================================*/
//.header-bottom__bottom-item.open .header-menu__link {
//    padding: 11px 0 11px 30px;
//}


#header-menu-other {
    //display: none;
    padding: 21px 22px 15px;

    a {
        text-transform: inherit;
        padding: 0 0 15px;
    }

    .header-menu__item {
        padding: 0;
        font-size: 14px;
        line-height: 17px;
        transition: 0.5s;
        color: #424242;
    }

    .inside-menu {
        min-height: 528px;
    }

    .header-menu__item:first-child {
        background: #fff;
    }

    .header-menu__item:hover .header-menu__icon .icon-svg {
        fill: #fff;
    }
}

.header-bottom__bottom-item:first-child #header-menu-other a {
    padding: 0;
}

.header-bottom__bottom-item {
    transition: 0.5s;
    @media (max-width: 1199.98px) {
        padding: 0;
    }

}


@media (max-width: 1199.98px) {
    .header-bottom__bottom-item:first-child:before {
        left: 0;
    }
}


/* ============================================== */
@media (max-width: 2500.98px) and (min-width: 1199.98px) {
    /* ==================== hover inside-menu */
    .header-bottom__bottom-item.open {
        &:hover {
            #header-menu-other {
                display: block;
            }
        }
    }

    .header-menu {
        &__item {
            &:hover {
                .inside-menu {
                    display: block;
                    color: initial;
                }
            }
        }
    }
}

/* ====================================== */

.menu-active {
    display: block;
}

.move-active {
    display: flex;
}

.header-menu__back {
    display: none;
}

.header-menu__icon img.hover {
    display: none;
}

.header-menu__item:hover .header-menu__icon img.hover {
    display: block;
    @media(max-width: 1199px) {
        display: none;
    }
}

.header-menu__item:hover .header-menu__icon img.active {
    display: none;
    @media(max-width: 1199px) {
        display: block;
    }
}

/* header-menu-adaptive */
.header-bottom__left-side .arrow, .header-top__mobile-login {
    display: none;
}

.header-bottom__bottom-item.header-bottom__bottom-catalog:hover ul {
    display: block;
}

.header-bottom__bottom-item.header-bottom__bottom-catalog {
    position: relative;
    &:hover {
        cursor: pointer;
    }
    &:before {
        position: absolute;
        top: 17px;
        right: 14px;
        content: '';
        height: 4px;
        width: 9px;
        transform: rotate(-180deg);
        background-image: url(/assets/icons/header/arrow-catalog.svg);
    }
    @media (max-width: 2500.98px) and (min-width: 1199.98px) {
        &:hover {
            ul {
                display: block;
            }
        }
    }
}


@media(max-width: 1199px) {
    .header__header-logo p {
        margin-top: 0;
    }
    .header-bottom__bottom-item.header-bottom__bottom-catalog:before {
        display: none;
    }
    .header {
        background: #fff;
        height: 78px;
        border-bottom: 0;
    }
    .header-top__left-side, .header-top__icon-burger:after {
        display: none;
    }
    .header__content {
        width: 100%;
    }
    .header-top {
        height: 78px;
        position: relative;
    }
    .header__header-logo {
        width: 135px;
        margin: 0 60px 0 0;
        height: 48px;
    }
    .header__header-right {
        width: 35px;
    }
    .header__content {
        justify-content: center;
        align-items: center;
        padding-left: 0;
    }
    .header__content:after {
        display: none;
    }
    .header-top {
        background: #fff;
        border: 0;
    }
    .header-bottom__left-side .arrow, .header-top__mobile-login {
        display: inline-block;
    }
    .header-bottom__bottom-item {
        width: 100%;
        max-width: 100%;
    }
    .bottom-side-contacts {
        order: 2;
    }
    .header__header-logo img {
        width: auto;
        height: auto;
    }
    .header-top__logo {
        width: 135px;
    }
    .header__header-bottom {
        background-color: #fff;
    }
    .header-bottom__left-side {
        background: #fff;
        padding-top: 0;
    }
    .header-bottom__bottom-item {
        padding: 0;

        a {
            padding: 17px 30px;
            display: block;
            text-align: center;
        }
    }

    .header-bottom__bottom-item:first-child:before {
        left: 30px;
        top: 20px;
    }
    .header-bottom__bottom-item {
        margin: 0;
    }
    .header-bottom .search-button__search-style {
        padding: 0 15px 0 30px;
    }
    .header-bottom__bottom-item:last-child {
        margin: 0 auto 40px auto;
    }
    .header-bottom__left-side {
        border-bottom: 0;
    }
    .header-top__mobile-login {
        order: 2;
        width: 315px;
        height: 45px;
        border: 1px solid #173542;
        padding: 12px 14px;
    }
    .header-top__login {
        justify-content: center;
        margin-bottom: 42px;
    }
    .header-top__login a, .header-top__login p {
        margin-right: 5px;
        margin-left: 0;
    }
    .header-top__login a:last-child {
        margin-right: 0;
    }
    .header-bottom__mobile {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 315px;
        margin: 0 auto;
        @media(max-width: 360px) {
            width: 90%;
        }
    }
    .bottom-side-contacts {
        padding: 35px 30px !important;
        display: block;
    }
    .bottom-side-contacts__schedule {
        padding: 0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.09em;
        color: #173542;
        margin-bottom: 10px;
    }
    .header-top__currency p {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #173542;
        margin: 0 0 3px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: #173542;
        opacity: 0.5;
        margin-bottom: 10px;
    }
    .button__link {
        width: 315px;
        height: 47px;
        line-height: 30px;
        padding: 0;
        border: none;
        position: relative;
        margin: 0 auto;
    }
    .button__link span {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        padding: 15px 0;
    }
    .bottom-side-contacts__button {
        padding: 40px 0 0 0;
    }
    .button__link span:after {
        width: 80%;
    }
    .header-bottom__bottom-item {
        position: relative;
    }
    .header-bottom__bottom-item .arrow {
        position: relative;
        top: inherit;
        right: inherit;
        margin-top: 10px;
        margin-left: 10px;
    }
    .header-menu {
        top: 56px;
    }
    .header-menu__item.mob-active .header-menu__icon img.hover {
        display: none;
    }
    .header-menu__item.mob-active .header-menu__icon img.active {
        display: block;
    }
    .header-menu__item.mob-active .icon-svg {
        fill: #530F0F;
    }
    #header-menu .header-menu__item {
        padding: 17px 0 22px 33px;
    }
    .header-menu__back {
        margin: 0 auto 10px;
    }
    .header-menu.menu-active {
        background: #F4F4F4;
        padding: 0 0 30px 0;
    }
    .header__header-menu.header-menu .header-menu__item.akcyi a, .header-menu a, .inside-menu__item a {
        display: block;
        width: 100%;
        height: 100%;
    }
    .header-menu__item a:before {
        width: 100%;
        bottom: -25px;
    }
    .header-menu.menu-active {
        padding-bottom: 180px;
    }
    .header-top__go-out {
        position: relative;
        padding-top: 40px;
        border-top: 1px solid rgba(23, 53, 66, 0.1);
        width: 120px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
    }
    .header-top__login-icon {
        position: relative;
    }
    .header-top__login-icon {
        top: 4px;
        left: inherit;
    }
    .header-top__go-out a {
        text-decoration: underline;
    }
    .header__header-bottom.header-bottom-registered .bottom-side-contacts {
        padding: 0 !important;
    }
    .header-top__go-out {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid rgba(23, 53, 66, 0.1);
        border-bottom: 1px solid rgba(23, 53, 66, 0.1);
        margin-top: 40px;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 50px;
    }
    .header-top__go-block {
        width: 120px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
    }
    .header-bottom.move-active {
        width: 101%;
        left: 0;
        top: 75px;
    }
    .header-bottom.move-active.right-move-active {
        left: -1px;
    }
    #header-menu .header-bottom__right-side {
        margin-bottom: 30px;
    }
    .header .container {
        padding: 0 !important;
    }
    #header-menu-other {
        padding: 0;
        width: 100%;
        left: 0;
        position: relative;
        top: 0;
        height: 0;
        transition: 0.5s;
    }
    #header-menu-other.open {
        height: auto;
        padding-top: 10px;
    }

}

@media(max-width: 1024px) {
    .header-menu__back {
        display: block;
    }
    .header-menu {
        &__item {

            a {
                .header-menu__item {
                    max-width: 470px;
                    margin: 0 auto;
                    position: relative;

                    a {
                        font-size: 14px;
                        line-height: 17px;
                        color: #25282E;
                    }
                }

                &:before {
                    content: '';
                    left: -11px;

                }
            }
        }
    }
    .header-bottom {
        display: flex;
        left: -2800px;
        transition: 0.5s;
        animation-delay: 0.5s;
        background: #FFF;
        min-height: calc(100vh - 54px);
        bottom: 0;
        position: absolute;
        z-index: 10000;
        width: 100%;
        height: 100%;
        overflow: auto;
        padding-bottom: 50px;
        //-webkit-transition: -webkit-transform .3s ease-in-out;
        //-o-transition: -o-transform .3s ease-in-out;
        //-o-transition: transform .3s ease-in-out;
        //transition: -webkit-transform .3s ease-in-out;
        //transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
        //-webkit-transform: translateX(-200%);
        //-ms-transform: translateX(-200%);
        //transform: translateX(-200%);
    }

    .header-menu__back img {
        margin-right: 20px;
        margin-top: 2px;
    }

    .header-bottom.move-active {
        transition: 0.5s;
        padding-bottom: 80px;
        animation-delay: 0.5s;
    }
    .header-menu__back {
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: #898989;
        padding-left: 35px;
        max-width: 470px;
        margin: 0 auto 25px;
    }

    .header-menu__item {
        max-width: 470px;
        margin: 0 auto;
        position: relative;
    }
    .header-menu.menu-active {
        left: 0;
        transition: 0.5s;
    }

    .bottom-side-contacts {
        padding: 35px 0 35px 0;
    }
    .header {
        z-index: 77;
    }
    .header-bottom.move-active.right-move-active {
        transition: 1s;
        animation-delay: 0.5s;
    }

}

@media(max-width: 480px) {

    .header-menu__item {
        max-width: 100%;
    }

}

@media(max-width: 360px) {
    .header-top__mobile-login {
        width: 260px;
    }
    .button__link {
        width: 90%;
    }
}
