/*--------------------------------------------------------------
# Icons
--------------------------------------------------------------*/

.icon__viber {
    fill: #784F98;
}

.icon__whatsapp {
    fill: #3FC451;
}

.icon__telegram {
    fill: #2BA0DA;
}