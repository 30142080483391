/*============================================================================
	#Global
==============================================================================*/
html {
    box-sizing: border-box;
    height: 100%;
    scroll-behavior: smooth;
}


*,
*:before,
*:after {
    box-sizing: inherit;
}
.lazy-background {
    background: inherit;
}
body {
    background: white;
    height: 100%;
    font-family: "Intel", sans-serif;
    animation: 0.5s;
    color: #292929;
    overflow-x: hidden;
    margin-right: calc(-1 * (100vw - 100%));
}

.no-scroll {
    overflow: hidden;
    position: relative;
}
.no-scroll-popup {
    overflow: hidden;
    position: relative;
}

img {
    height: auto;
    max-width: 100%;
}

svg {
    vertical-align: top;
}
.wrapper {
    margin: 0 auto;
    max-width: 1920px;
}
.container {
    max-width: 1180px;
    padding: 0;
    margin: 0 auto;
    width: 100%;
}
html {
    //overflow-x: hidden;
    //margin-right: calc(-1 * (100vw - 100%));

    //margin-left: calc(100vw - 100%);
    //margin-right: 0;

    //width:100vw;
    //overflow-x:hidden;
    //margin-right: calc(-1 * (100vw - 100%));
    box-sizing: border-box;
    height: 100%;
    scroll-behavior: smooth;
    width: 100vw;
    margin-right: calc(-1 * (100vw - 100%));
}
.root {
    margin-top: 105px;
}
.no-scroll-popup {
    position: static;
    height: auto;
    overflow-y: hidden;
    //position: fixed;
    @media (max-width: 1199.98px) {
        position: relative;
        height: inherit;
        overflow: hidden;
    }
}

h1 {
    margin: 0;
}

@media (max-width: 1199.98px) {
    .container {
        padding: 0 15px;
        max-width: 100%;
    }
    .root {
        margin-top: 55px;
    }
}
