.card-company{
    border: 0;
    h1{
        font-family: Raleway;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        color: #0F0D3F;
        margin-bottom: 10px;
    }
    p.subtitle{
        margin: 0 0 24px;
        font-family: Raleway;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #8B8AA8;
    }
}
.company-page{
    display: flex;
    border: none;
    justify-content: space-between;
    &__balance{
        width: 35%;
        border: 1px solid #D4D3F8;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 16px;
    }
    &__left{
        width: 63%;
    }
}
.balance{
    &__title{
        font-family: Raleway;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #0F0D3F;
        margin-bottom: 16px;
    }
}
.balance__content .form-group{
    .input-group {
        display: block;
    }
    .label{
        display: block;
        margin-bottom: 5px;
        font-family: Raleway;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #8B8AA8;
    }
    input{
        border: 0;
        border-bottom: 1px solid #E2E2E9;
        width: 100%;
        font-family: Raleway;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        color: #0F0D3F;
        height: 28px;
        margin-bottom: 32px;
        padding: 0;
        background: inherit;
    }
}
.legal{
    border: 1px solid #D4D3F8;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 10px;
    &__title{
        font-family: Raleway;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #0F0D3F;
        margin-bottom: 24px;
    }

    &__head{
        display: flex;
        p{
            font-family: Raleway;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height:15px;
            color: #8B8AA8;
            margin:0;
            &:first-child{
                width: 60%;
            }
            &:last-child{
                width: 40%;
                text-align: right;
            }
        }
    }
    &__item{
        display: flex;
        padding: 16px 0;
        border-bottom: 1px solid #D4D3F8;
        &:last-child{
            border-bottom: 0;
        }
        p{
            &:first-child{
                width: 60%;
                font-family: Raleway;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #4D4C67;
                margin:0;
            }
            &:last-child{
                width: 40%;
                font-family: Raleway;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #333092;
                text-align: right;
                margin:0;
            }
        }
    }
}
.manager{
    border: 1px solid #D4D3F8;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    &__title{
        font-family: Raleway;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #0F0D3F;
        margin-bottom: 24px;
    }

    &__head {
        display: flex;
        p {
            font-family: Raleway;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height:15px;
            color: #8B8AA8;
            margin:0;
            &:first-child{
                width: 50%;
            }
            &:nth-child(2), &:nth-child(3){
                width: 25%;
            }
        }
    }
    &__item{
        display: flex;
        align-items: center;
        padding: 16px 0;
        border-bottom: 1px solid #D4D3F8;
        &:last-child{
            border-bottom: 0;
        }
        p{
            font-family: Raleway;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #0F0D3F;
            margin:0;
            span{
                display: block;
                font-family: Raleway;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                color: #8B8AA8;
            }
            &:first-child{
                width: 50%;
            }
            &:nth-child(2){
                width: 25%;
                font-family: Raleway;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                color: #0F0D3F;
            }
            &:nth-child(3){
                width: 25%;
                font-family: Raleway;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #0F0D3F;
            }
        }



    }
}
.delivery-addresses{
    border: 1px solid #D4D3F8;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px;
    &__title-block{
        display: flex;
        justify-content: space-between;
        margin-bottom: 28px;
        p{

            font-family: Raleway;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #0F0D3F;
            margin-bottom: 24px;
        }
        button{
            width: 156px;
            height: 32px;
            background: #F47B20;
            border-radius: 4px;
            text-transform: inherit;
            font-family: Raleway;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 17px;
            color: #FFFFFF;
            text-align: center;

        }
    }

    &__item{
        display: flex;
        padding: 16px 0;
        border-bottom: 1px solid #D4D3F8;
        &:last-child{
            border-bottom: 0;
        }
        p{
            margin: 0;
            font-family: Raleway;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #4D4C67;
        }
    }
}

@media (max-width: 1023px) {

    .delivery-addresses__title-block p {
        font-size: 17px;
        line-height: 18px;
        margin-bottom: 25px;
        margin-top: 10px;
    }

}
@media (max-width: 767px) {
.balance__content form{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
    .company-page {
        flex-wrap: wrap;
    }

    .company-page__balance {
        width: 100%;
        order: 1;
    }

    .company-page__left {
        width: 100%;
        margin-top: 15px;
        order: 2;
    }
    .balance__content form .form-group:nth-child(6) input, .balance__content form .form-group:nth-child(5) input, .balance__content form .form-group:nth-child(4) input {
        margin-bottom: 0;
    }
    .balance__content form{
        .form-group {
            margin-bottom: 0;
        }
    }
}
@media (max-width: 480px) {
    .delivery-addresses__item {
        display: block;
    }
    .manager__head, .manager__item{
        flex-wrap: wrap;
    }
     .manager__item p{
        width: 100%;
    }
    .manager__item p:nth-child(2) {
        order: 3;
        width: 100%;
        margin-top: 4px;
    }
    .manager__item p:nth-child(3) {
        width: 100%;
    }
    .delivery-addresses__title-block p {
        margin-bottom: 15px;
        margin-top: 0;
    }
    .delivery-addresses__title-block{
        margin-bottom: 15px;
    }
    .balance__content form .form-group {
        width: 48%;
    }
   .manager__head{
       display: none;
   }
    .company-page__balance, .manager {
        padding: 16px 16px 0;
    }
    .manager__title {
        margin-bottom: 0;
    }
    .manager__item p:first-child {
        width: 100%;
        margin-bottom: 16px;
    }
}
