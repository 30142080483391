/*============================================================================
	#Typography
==============================================================================*/
html {
	font-size: 62.5%;
}

body,
button,
input,
select,
optgroup,
textarea {
    font-family: 'Intel', sans-serif;
	font-size: 1.5rem;
    line-height: 1.6;
    font-weight: normal;
	// letter-spacing: 0.025em;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
}

.seo-text {
    &:first-child, &:last-child{
        margin-bottom: 0;
    }
    h1, h2, h3, h4, h5, h6, p, a {
        color: #666666;
        margin-bottom: 30px;
    }

    h1 {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        color: #25282E;
        margin-bottom: 40px;
        @media (max-width: 767.98px) {
            margin-bottom: 25px;
            font-size: 24px;
            line-height: 26px;
        }
    }

    h2 {
        font-weight: 600;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 30px;
        @media (max-width: 767.98px) {
            margin-bottom: 25px;
            font-size: 22px;
            line-height: 26px;
        }
    }

    h3 {
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 30px;
        @media (max-width: 767.98px) {
            margin-bottom: 25px;
            font-size: 20px;
            line-height: 24px;
        }
    }

    h4 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 30px;
        @media (max-width: 767.98px) {
            margin-bottom: 25px;
            font-size: 18px;
            line-height: 24px;
        }
    }

    h5 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 30px;
        @media (max-width: 767.98px) {
            margin-bottom: 25px;
            font-size: 16px;
            line-height: 24px;
        }
    }

    h6 {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 30px;
        @media (max-width: 767.98px) {
            margin-bottom: 25px;
            font-size: 14px;
            line-height: 24px;
        }
    }

    p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 1.3px;
        @media (max-width: 1023.98px) {
            margin-bottom: 25px;
            font-size: 14px;
            line-height: 24px;
        }

        a {
            background: #E3E6F0;
            font-size: 1.4rem;
            line-height: 2.6rem;
        }
    }

    blockquote {
        font-style: italic;
        font-weight: 500;
        font-size: 18px;
        position: relative;
        line-height: 28px;
        text-align: center;
        padding: 48px 30px;
        border-top: 1px solid #C40A0A;
        margin-top: 50px;

        @media (max-width: 767.98px) {
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            padding: 25px 10px;
            margin-top: 30px;
        }

        &::after {
            content: '';
            top: -17px;
            position: absolute;
            height: 35px;
            font-size: 60px;
            background-color: #fff;
            background-repeat: no-repeat;
            background-image: url('../../../assets/icons/quote.svg');
            background-position: center;
            background-size: 42px;
            left: 50%;
            transform: translateX(-50%);
            width: 154px;
            @media (max-width: 767.98px) {
                background-size: 30px;
                width: 85px;
            }
        }
    }
    ul {
        margin-bottom: 40px;
        @media (max-width: 767.98px) {
            margin-bottom: 30px;
        }
        li {
            font-weight: 400;
            position: relative;
            font-size: 14px;
            line-height: 28px;
            padding-left: 15px;
            color: #173542;
            opacity: 0.6;
            &:before {
                position: absolute;
                top: 10px;
                left: 0;
                width: 6px;
                height: 6px;
                background: #AA2A11;
                content: '';
                border-radius: 50%;
                @media (max-width: 767.98px) {
                    top: 10px;
                }
            }
        }
    }

    ol {
        margin-bottom: 40px;
        padding-left: 15px;
        @media (max-width: 576.98px) {
            margin-bottom: 20px;
        }

        li {
            font-weight: 400;
            position: relative;
            font-size: 14px;
            line-height: 32px;
            padding-left: 0;
            color: #173542;
            opacity: 0.6;
            @media (max-width: 767.98px) {
                line-height: 28px;
            }
        }
    }
    p{
        iframe{
            width: 100%;
            height: 400px;
            @media (max-width: 767.98px) {
                height: 250px;
            }
        }
    }
    iframe{
        width: 100%;
        height: 400px;
        @media (max-width: 767.98px) {
            height: 250px;
        }
    }
}
.typography {
    &:first-child, &:last-child{
        margin-bottom: 0;
    }
    h1, h2, h3, h4, h5, h6, p, a {
        color: rgba(23, 53, 66, 1);
    }

    h1 {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        color: #25282E;
        margin-bottom: 40px;
        @media (max-width: 767.98px) {
            margin-bottom: 25px;
            font-size: 24px;
            line-height: 26px;
        }
    }

    h2 {
        font-weight: 600;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 30px;
        @media (max-width: 767.98px) {
            margin-bottom: 25px;
            font-size: 22px;
            line-height: 26px;
        }
    }

    h3 {
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 30px;
        @media (max-width: 767.98px) {
            margin-bottom: 25px;
            font-size: 20px;
            line-height: 24px;
        }
    }

    h4 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 30px;
        @media (max-width: 767.98px) {
            margin-bottom: 25px;
            font-size: 18px;
            line-height: 24px;
        }
    }

    h5 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 30px;
        @media (max-width: 767.98px) {
            margin-bottom: 25px;
            font-size: 16px;
            line-height: 24px;
        }
    }

    h6 {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 30px;
        @media (max-width: 767.98px) {
            margin-bottom: 25px;
            font-size: 14px;
            line-height: 24px;
        }
    }

    p {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin: 0 0 25px 0;
        @media (max-width: 1023.98px) {
            margin-bottom: 25px;
        }

        a {
            background: #E3E6F0;
            font-size: 1.4rem;
            line-height: 2.6rem;
        }
    }

    blockquote {
        font-style: italic;
        font-size: 16px;
        line-height: 24px;
        color: #173542;
        padding: 48px 30px;
        margin-top: 50px;

        @media (max-width: 767.98px) {
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            padding: 25px 10px;
            margin-top: 30px;
        }

        &::after {
            content: '';
            top: -17px;
            position: absolute;
            height: 35px;
            font-size: 60px;
            background-color: #fff;
            background-repeat: no-repeat;
            background-image: url('../../../assets/icons/quote.svg');
            background-position: center;
            background-size: 42px;
            left: 50%;
            transform: translateX(-50%);
            width: 154px;
            @media (max-width: 767.98px) {
                background-size: 30px;
                width: 85px;
            }
        }
    }
    ul {
        margin-bottom: 40px;
        @media (max-width: 767.98px) {
            margin-bottom: 30px;
        }
        li {
            font-weight: 500;
            position: relative;
            font-size: 16px;
            line-height: 24px;
            text-indent: 18px;
            margin-bottom: 24px;
            &:before {
                position: absolute;
                top: 11px;
                left: 1px;
                content: '';
                width: 6px;
                height: 6px;
                background: #173542;
                border-radius: 50%;
                @media (max-width: 767.98px) {
                    top: 10px;
                }
            }
        }
    }

    ol {
        margin-bottom: 40px;
        padding-left: 15px;
        @media (max-width: 576.98px) {
            margin-bottom: 20px;
        }

        li {
            font-weight: 400;
            position: relative;
            font-size: 14px;
            line-height: 32px;
            padding-left: 0;
            @media (max-width: 767.98px) {
                line-height: 28px;
            }
        }
    }
    p{
        iframe{
            width: 100%;
            height: 400px;
            @media (max-width: 767.98px) {
                height: 250px;
            }
        }
    }
    iframe{
        width: 100%;
        height: 400px;
        @media (max-width: 767.98px) {
            height: 250px;
        }
    }
}
@media (max-width: 767.98px) {
    .typography .responsive-iframe iframe {
        width: 100%;
    }

    .post__typography img {
        padding: 0 !important;
    }


    .typography blockquote::after {
        top: 20px;
        left: 10px;
        width: 20px;
        height: 20px;
    }


    .typography {
        h2 {
            font-size: 2.4rem;
            line-height: 1.25;
        }

        h3 {
            font-size: 2.2rem;
            line-height: 1.25;
        }

        h4 {
            font-size: 2.0rem;
            line-height: 1.22;
        }

    }

}

/* ======= services, delivery styles*/
.services-list {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;

    li {
        padding: 0 0 10px 0;
        text-indent: 15px;
        position: relative;

        &:last-child {
            padding: 0;
        }

        &:before {
            content: '';
            position: absolute;
            top: 8px;
            left: 0;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #c62506;
        }
    }
}
