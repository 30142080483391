.default-select {
    display: inline-block;
    cursor: pointer;
    position: relative;
    margin-top: -3px;
    z-index: 2;
    width: 100%;
    margin-right: -1px;
    height: 20px;
    box-sizing: border-box;
}

.default-select::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 5px;
    top: 49%;
    right: -3px;
    background: url(../icons/header/arrow.svg);
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.sort-select__current {
    display: block;
    padding: 1px 40px 0 13px;
    font-size: 14px;
    line-height: 20px;
    color: #959392;
    height: 22px;
}

.sort-select__list {
    position: absolute;
    background: #EFEDEE;
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: calc(100% + 2px);
    left: 2px;
    box-sizing: border-box;
}

.container--active {
    border: 0;
    background: #EFEDEE;
    z-index: 666;
    position: absolute;
    top: 24px;
    left: 2px;
}


.sort-select__item {

    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #25282E;
    opacity: 0.6;
    transition: 0.2s;
}

.sort-select__item:hover {
    background: rgba(196, 196, 196, 0.2);
    color: #25282E;
    opacity: 1;
}
.sort-select__item a{
    color: #25282E;
    opacity: 0.6;
    display: block;
    padding: 6px 10px 8px 10px;
}
.sort-select__item a:hover {
    color: #25282E;
    opacity: 0.6;
}
.catalog-page__sort {
    display: flex;
}

.catalog-page__sort p {
    margin-right: 8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #898989;
}

.sort-select__item.active {
    display: none;
}

#select-list {
    max-height: 0;
    overflow: hidden;
    transition: max-height .3s ease;
}

.sort-select__list {
    max-height: 0;
    overflow: hidden;
    transition: max-height .3s ease;
}



