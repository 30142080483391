/*! Varriables */

// Fonts

$fontPrimary: "Inter", sans-serif;
$fontSecondary: "AireBoldPro", sans-serif;

// Colors

// Title colors
$titleColor: #424242;

// Text colors
$textPrimaryColor: #666666;
$textSecondaryColor: #424242;
$textThirdColor: #a0a0a0;

$accentColor: #856d65;
