/*============================================================================
	#Fonts
==============================================================================*/

@font-face {
    font-family: "AireBoldPro";
    src: url("/assets/fonts/AireBoldPro.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("/assets/fonts/Inter-Regular.woff2") format("woff2"),
        url("/assets/fonts/Inter-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("/assets/fonts/Inter-Medium.woff2") format("woff2"),
        url("/assets/fonts/Inter-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("/assets/fonts/Inter-SemiBold.woff2") format("woff2"),
        url("/assets/fonts/Inter-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("/assets/fonts/Inter-Bold.woff2") format("woff2"),
        url("/assets/fonts/Inter-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter-Light";
    src: url("/assets/fonts/Inter-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
