
.btn {
    width: 208px;
    height: 48px;
    background: rgba(248, 245, 243, 0.22);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    margin: 0 auto;
    text-align: center;
    border-bottom-left-radius: 100px;
    transition: 0.5s;

    &:hover {
        background: rgba(248, 245, 243, 0.5);
        border: 1px solid #FFFFFF;
        cursor: pointer;
    }

    a {
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
        text-align: center;
        padding: 13px 0;
        display: block;
        letter-spacing: 1.3px;
    }
}


.btn-all {
    a {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 1.3px;
        color: #856D65;
        position: relative;
        z-index: 2;
    }
}

.btn-all {
    position: relative;
    display: flex;

    &:before {
        content: '';
        position: absolute;
        top: -30px;
        left: 50px;
        width: 180px;
        height: 85px;
        background-image: url(../../assets/images/home/block1/ellipse.svg);
        background-position: center;
        background-repeat: no-repeat;
        z-index: 1;
    }
}

.btn-all {
    .icon {
        position: relative;
        width: 30px;
        height: 1px;
        background: #856D65;
        margin-top: 11px;
        margin-left: 13px;
        transition: 0.5s;

        &:hover {
            width: 62px;
        }

        img {
            position: absolute;
            top: -5px;
            right: -4px;
            width: 7px;
            height: 12px;

        }
    }

    &:hover {
        cursor: pointer;

        .icon {
            width: 62px;
        }
    }
}

@media (max-width: 2500.98px) and (min-width: 1199.98px) {
    .button__link:hover {
        cursor: pointer;
    }
    .button__link:hover:before {
        height: 100%;
    }
    .button__link:hover:after {
        width: 100%;
    }
    .button__link span:hover:before {
        height: 100%;
    }
    .button__link span:hover:after {
        width: 100%;
    }
}
