
.footer {
    max-width: 1920px;
    margin: 0 auto;
    background: #CCCDD1;
    position: relative;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.5);
    height: 365px;


    &__footer-top {
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-bottom: 0;
        @media(max-width: 767px) {
            flex-wrap: wrap;
        }
        @media(max-width: 576px) {
            display: block;
        }

        .footer-top__subtitle {
            margin-bottom: 40px;
            width: 52%;
            margin-top: 30px;
            @media(max-width: 1199px) {
                width: 51%;
                flex-wrap: wrap;
            }
        }

        .footer-top__subtitle:last-child {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .footer-top__login {
                display: flex;
                @media(max-width: 1199px) {
                    width: 150px;
                }
                a, p {
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.05em;
                    transition: 0.5s;
                    color: #CCBDB8;
                    transition: 0.5s;
                    margin: 0 2px;

                    &:hover {
                        color: #ECE7E6;
                    }
                }
            }

            @media(max-width: 1023px) {
                flex-wrap: wrap;
            }
        }
    }

    &__footer-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media(max-width: 1023px) {
            padding: 20px 0;
        }
        @media(max-width: 767px) {
            display: block;
            padding: 20px 0 20px 0;
        }

    }
}

.footer-top {
    &__item {
        margin-bottom: 10px;
    }

    &__logo {
        width: 138.17px;
        height: 138.44px;
        margin: 58px auto 9px;

        a {
            width: 100%;
            height: 100%;
            display: block;

        }

        @media(max-width: 1199px) {
            width: 220px;
        }
        @media(max-width: 767px) {
            position: relative;
            top: 0;
        }
    }

    &__left-side {
        width: 252px;
        text-align: center;
        position: relative;
        @media(max-width: 767px) {
            flex: inherit;
            width: 100%;
            justify-content: center;
            margin: 0;
        }
        @media(max-width: 576px) {
            justify-content: left;
        }

        p {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.05em;
            color: #FFFFFF;
        }
    }

    &__column {
        font-family: 'Intel', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 32px;

        a {
            color: #AAB9CF;
            transition: 0.5s;

            &:hover {
                color: #fff;
            }
        }

        &:last-child {
            margin: 0 0 0 75px;
            @media(max-width: 480px) {
                margin: 0 0 0 25px;
            }
        }
    }

    &__title {
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        text-transform: uppercase;
        color: #1C3842;
        margin-bottom: 25px;
    }

    &__item {
        a {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.05em;
            transition: 0.5s;
            color: #CCBDB8;
            transition: 0.5s;

            &:hover {
                color: #ECE7E6;
            }
        }
    }

    &__social-left {
        p {
            display: flex;

            .icon-svg {
                width: 23px;
                height: 23px;
                fill: #fff;
                margin-right: 18px;
            }

            span {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.05em;
                color: #FFFFFF;
                width: 85%;
            }
        }

        a {
            display: block;
            transition: 0.5s;

            span {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.05em;
                color: #FFFFFF;
            }

            &:hover {
                span {
                    color: rgba(255, 255, 255, 0.55);
                }
            }

            .icon-svg {
                width: 23px;
                height: 23px;
                fill: #fff;
                margin-right: 18px;
            }
        }
    }

    &__center-side {
        margin-top: 38px;
        min-width: 230px;
        @media(max-width: 1023px) {
            margin-top: 0;
            min-width: 175px;
        }
        @media(max-width: 767px) {
            width: 43%;
        }
        @media(max-width: 576px) {
            width: 100%;
        }
    }

    &__right-side {
        width: 64%;
        margin-top: 30px;
        position: relative;
        box-sizing: border-box;
        @media(max-width: 1199px) {
            width: 70%;
        }
        @media(max-width: 1023px) {
            padding: 0 0 29px 35px;
        }
        @media(max-width: 767px) {
            padding: 0 0 29px 75px;
            width: 43%;
        }
        @media(max-width: 576px) {
            width: 100%;
        }
        @media(max-width: 480px) {
            padding: 0 0 29px 25px;
        }

        .footer-top__top-side, .footer-top__bottom-side {
            display: flex;
        }

        .footer-top__bottom-side {
            .footer-top__phones {
                width: 52%;
                @media(max-width: 1199px) {
                    width: 51%;
                }

                .icon-svg {
                    width: 23px;
                    height: 23px;
                    fill: #fff;
                    margin-right: 18px;
                }
            }

            .footer-top__top-row {
                width: 48%;
                display: flex;
                flex-wrap: wrap;
                @media(max-width: 1199px) {
                    width: 59%;
                }
            }

            a {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.05em;
                color: #FFFFFF;
                margin-bottom: 12px;
                display: block;
                transition: 0.5s;

                &:hover {
                    color: rgba(255, 255, 255, 0.55);
                }
            }
        }
    }

    &__social {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    &__social-right {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        text-align: right;
        color: #AAB9CF;
    }

    &__phone {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
        color: #66707D;
        transition: 0.5s;
        @media (max-width: 2500.98px) and (min-width: 1024.98px) {
            &:hover {
                color: #87B7FF;
            }
        }
        @media(max-width: 1199px) {
            color: #AAB9CF;
        }
    }

    &__messengers {
        display: flex;
        margin-top: 8px;
        margin-left: -2px;

        a {
            transition: 0.5s;

            svg {
                width: 25px;
                height: 25px;
                fill: #CCBDB8;
                transition: 0.5s;

                &:hover {
                    path:first-child {
                        fill: #1C3842;

                    }
                }
            }


        }
    }

    &__bottom-row {
        display: flex;
        align-items: center;
        margin: 25px 0 0 0;
        width: 500px;
    }
}

.footer-top__right-side .footer-top__bottom-side .footer-top__phones .footer-top__messengers {
    .icon-svg {
        width: 33px;
        height: 25px;
        fill: #CCBDB8;
        transition: 0.5s;
        position: relative;
    }
}

.alatra_icon, .footer_icon {
    max-width: 135px;
}

.footer_icon {
    margin-right: 85px;
}

.footer-top__right-side .footer-top__bottom-side .footer-top__messengers a {
    position: relative;

    &:before {
        position: absolute;
        top: -10px;
        left: -8px;
        background: rgba(255, 255, 255, 0.12);
        width: 51px;
        height: 45px;
        content: '';
        opacity: 0;
        transition: 0.5s;
    }

    &:hover {
        &:before {
            opacity: 1;
        }

        svg {
            fill: #ECE7E6 !important;
        }
    }
}

.footer-bottom {
    &__copyright {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.05em;
        color: #C4C4C4;
        width: 252px;
        text-align: center;
        @media(max-width: 1023px) {
            padding-left: 5px;
        }
        @media(max-width: 767px) {
            padding-left: 0;
        }

    }

    &__support-text {
        span {
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.05em;
            color: #C5C5C5;
            width: auto;
        }
    }

    &__creators {
        display: flex;
        margin-top: -3px;
        width: 70%;
        justify-content: flex-end;
        @media(max-width: 1023px) {
            padding-right: 0;
        }

        a {
            opacity: 0.5;
        }
    }

    &__support-text {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @media(max-width: 767px) {
            align-items: flex-start;
        }
    }

    &__art-sites {
        margin: 0 0 0 20px;
    }
}

.outside-arrow {
    position: fixed;
    width: 66px;
    height: 66px;
    right: 50px;
    bottom: 50px;
    display: inline-block;
    background: rgba(0, 0, 0, 0.10);
    z-index: 66;
    border-radius: 50%;
    transition: 0.5s;
    &:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.20);
    }

    &__pict-arrow {
        height: auto;
        width: 22px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: relative;
        z-index: 10;
    }
}

.outside-arrow.scrolltop_visible {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}
.footer-top__right-side .footer-top__bottom-side a.footer_icon{
    max-width: 135px;
    margin-top: 15px;
    margin-right: 85px;
}
@media(max-width: 1199px) {
    .footer-top__left-side, .footer-bottom__copyright {
        width: 220px;
    }
    .footer-top__social {
        margin-bottom: 10px;
    }
}

@media(max-width: 1023px) {
    .outside-arrow {
        bottom: 70px;
        right: 20px;
    }

    .footer-top__logo, .footer-top__left-side, .footer-bottom__copyright {
        width: 150px;
    }
    .footer-top__left-side p {
        font-size: 12px;
        line-height: 18px;
    }
    .footer-top__right-side {
        width: 77%;
    }
    .footer-top {

        &__title {
            margin: 25px 0 0 0;
        }

        &__top-row {
            max-width: 300px;

            &:before {
                content: '';
                width: 300px;
            }
        }

        &__social {
            display: inline-block;
        }

        &__networks {
            margin: 25px 0;
        }

        &__social-right {
            text-align: left;
            margin: 30px 0 0 0;
        }
    }
    .footer-bottom {
        &__support-text {
            text-align: left;
        }

        &__creators {
            width: 300px;
            margin: 0 auto;
        }
    }
    .subtitle-param {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;
        height: 100px;
    }
    .footer-top__logo, .footer-top__left-side, .footer-bottom__copyright {
        margin-top: 20px;
    }
    .footer__footer-top .footer-top__subtitle {
        margin-bottom: 30px;
        margin-top: 10px;
    }
    .footer_icon {
        margin-right: 10px;
    }
    .footer-top__social {
        margin-bottom: 10px;
    }
    .footer-top__right-side {
        padding: 0 0 0 20px;
    }
    .footer-bottom__copyright {
        width: auto;
        margin-top: -3px;
    }
    .footer-bottom__creators {
        width: auto;
        margin: 0 20px 0 0;
    }
    .footer {
        height: auto
    }
    .footer__footer-bottom {
        padding: 20px 0 10px;
    }
    .footer-top__right-side .footer-top__bottom-side a.footer_icon {
        margin-right: 20px;
    }
    .footer-top__right-side .footer-top__bottom-side a.alatra_icon, .footer-top__right-side .footer-top__bottom-side a.footer_icon {
        margin-bottom: 0;
    }

}


@media(max-width: 767px) {
    .footer-bottom__creators {
        width: 300px;
        margin: 0 auto;
    }
    .footer-top__item {
        width: 50%;
    }
    .footer__footer-top .footer-top__subtitle:last-child .footer-top__login {
        width: 50%;
    }
    .main-blog {
        margin-bottom: 0;
    }
    .footer-top__logo {
        height: auto;
        margin: 30px auto 10px;
    }
    .footer-top__logo, .footer-top__left-side, .footer-bottom__copyright {
        width: 100%;
    }
    .footer-top__right-side {
        width: 100%;
        padding: 0;
    }
    .footer-bottom__art-sites {
        margin: 8px 0 0 20px;
        width: 150px;
    }
    .footer-top__item a, .footer__footer-top .footer-top__subtitle:last-child .footer-top__login a {
        width: auto;
    }
}

@media(max-width: 576px) {
    .footer-top__right-side .footer-top__bottom-side {
        display: block;
    }
    .footer-top__right-side .footer-top__bottom-side .footer-top__phones, .footer-top__right-side .footer-top__bottom-side .footer-top__top-row {
        width: 100%;
    }
    .footer-bottom__art-sites {
        margin: 8px 0 0 0;
        width: 165px;
    }
    .footer-bottom__support-text span {
        width: 150px;
        line-height: 18px;
        padding-top: 5px;
    }
    .footer-bottom__creators {
        width: 265px;
    }
    .footer-top__logo {
        width: 105px;
        height: 105px;
    }
    .footer__footer-top .footer-top__subtitle {
        width: 90%;
    }
    .footer-top__left-side p {
        width: 232px;
        margin: 0 auto;
    }
    .outside-arrow {
        width: 45px;
        height: 45px;
    }
    .outside-arrow__pict-arrow {
        width: 15px;
    }
}

@media(max-width: 480px) {
    .footer-top__subtitle {
        .footer-top__item:nth-child(1), .footer-top__item:nth-child(3), .footer-top__item:nth-child(5) {
            width: 60%;
        }

        .footer-top__item:nth-child(2), .footer-top__item:nth-child(4), .footer-top__item:nth-child(6) {
            width: 40%;
        }

    }
    .footer__footer-top .footer-top__subtitle:last-child .footer-top__login {
        width: 40%;
    }
    .footer-top__social-left p span, .footer-top__social-left a span {
        font-size: 14px;
    }
    .footer-top__social-left a .icon-svg {
        margin-right: 13px;
    }
    .footer-top__right-side .footer-top__bottom-side a {
        font-size: 14px;
    }
    .footer-top__right-side .footer-top__bottom-side .footer-top__phones .icon-svg {
        margin-right: 10px;
    }
    .footer-bottom__support-text span, .footer-bottom__copyright {
        font-size: 12px;
    }
    .footer-top__logo {
        width: 90px;
    }
    .footer-top__right-side {
        margin-top: 15px;
    }

}
@media(max-width: 380px) {
    .footer-top__item a,  .footer__footer-top .footer-top__subtitle:last-child .footer-top__login a, .footer__footer-top .footer-top__subtitle:last-child .footer-top__login p {
        font-size: 14px;
        line-height: 20px;
    }
    .footer-top__subtitle .footer-top__item:nth-child(1), .footer-top__subtitle .footer-top__item:nth-child(3), .footer-top__subtitle .footer-top__item:nth-child(5), .footer-top__subtitle .footer-top__item:nth-child(2), .footer-top__subtitle .footer-top__item:nth-child(4), .footer-top__subtitle .footer-top__item:nth-child(6), .footer__footer-top .footer-top__subtitle:last-child .footer-top__login {
        width: 50%;
    }

}
