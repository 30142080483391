//.modal {
//    display: none;
//
//    .modal-bg {
//        display: block;
//        background: rgba(229, 232, 244, 0.8);
//        width: 100%;
//        z-index: -1;
//        position: fixed;
//        top: 0;
//        left: 0;
//        overflow: auto;
//        height: 100%;
//    }
//
//    &__wrapper {
//        width: 557px;
//        position: absolute;
//        top: 0;
//        left: 50%;
//        transform: translateX(-50%);
//        padding: 80px 0;
//        z-index: 1;
//    }
//
//    &__header {
//        padding: 61px 50px 0;
//        text-align: center;
//        position: relative;
//        @media(max-width: 767px) {
//            padding: 26px 5px 15px;
//        }
//
//        .modal-close {
//            position: absolute;
//            width: 12px;
//            height: 12px;
//            top: 17px;
//            right: 25px;
//            background: inherit;
//            @media(max-width: 767px) {
//                top: 10px;
//                right: 15px;
//            }
//
//            &:hover {
//                cursor: pointer;
//            }
//        }
//    }
//
//    &__title {
//        font-family: 'AireBoldPro';
//        font-style: normal;
//        font-weight: bold;
//        font-size: 48px;
//        line-height: 48px;
//        letter-spacing: 0.04em;
//        color: #424242;
//        margin-bottom: 15px;
//        @media(max-width: 767px) {
//            font-size: 22px;
//            line-height: 26px;
//            margin-bottom: 0;
//        }
//    }
//
//    &__content {
//        width: 456px;
//        margin: 0 auto;
//        padding-bottom: 0;
//        @media(max-width: 767px) {
//            padding-bottom: 30px;
//        }
//
//    }
//
//    &__text {
//        font-weight: normal;
//        font-size: 16px;
//        line-height: 24px;
//        text-align: center;
//        letter-spacing: 0.04em;
//        color: #666666;
//        text-align: center;
//        margin-bottom: 22px;
//
//        @media(max-width: 767px) {
//            font-size: 14px;
//            line-height: 20px;
//        }
//
//    }
//    .main-form{
//        width: 100%;
//    }
//    .main-form .button-send {
//        width: 208px;
//        height: 48px;
//        background: rgba(255, 255, 255, 0.26);
//        border: 1px solid #856D65;
//        box-sizing: border-box;
//        font-size: 18px;
//        line-height: 22px;
//        color: #856D65;
//        margin-top: 28px;
//        @media(max-width: 767px) {
//            width: 185px;
//            height: 40px;
//            font-size: 16px;
//            line-height: 12px;
//            margin-top: 15px;
//        }
//    }
//}
//
//.modals__modal {
//    input {
//        background: rgba(255, 255, 255, 0.26);
//        border: 1px solid rgba(0, 0, 0, 0.47);
//        box-sizing: border-box;
//        border-radius: 50px;
//        width: 100%;
//        height: 50px;
//        @media(max-width: 767px) {
//            height: 40px;
//        }
//    }
//}
//
//.modal__wrapper {
//    background-image: url(/../assets/images/model/popup/bg.jpg);
//    background-position: center;
//    background-repeat: no-repeat;
//    background-size: cover;
//}
//
//.modal .main-form__input-group .req-star {
//    top: 10px;
//}
//
//.main-form__input-group .req-star {
//    position: absolute;
//    width: 9px;
//    height: 16px;
//    top: -16px;
//    right: 0;
//}
//
//.modal .main-form__input-group {
//    position: relative;
//}
//
//textarea {
//    padding: 13px 25px;
//    width: 100%;
//    height: 100%;
//    max-width: 100%;
//    font-size: 14px;
//    line-height: 24px;
//    letter-spacing: 0.04em;
//    color: rgba(0, 0, 0, 0.35);
//    min-height: 134px;
//    min-width: 100%;
//    background: rgba(255, 255, 255, 0.26);
//    border: 1px solid rgba(0, 0, 0, 0.47);
//    box-sizing: border-box;
//    border-radius: 20px;
//
//    &:active {
//        border: 1px solid rgba(139, 154, 160, 1);
//    }
//
//    &:focus {
//        border: 1px solid rgba(139, 154, 160, 1);
//    }
//}
//
//.main-form .button-send {
//    width: 197px;
//    height: 48px;
//    border-top-right-radius: 50px;
//    border-bottom-right-radius: 50px;
//    font-size: 18px;
//    line-height: 22px;
//    color: #856D65;
//    margin: 0 auto;
//    text-align: center;
//    border-bottom-left-radius: 100px;
//    transition: 0.5s;
//    padding: 13px 0;
//    display: block;
//    letter-spacing: 1.3px;
//    background: rgba(255, 255, 255, 0.26);
//    border: 1px solid #856D65;
//    box-sizing: border-box;
//
//    @media (max-width: 2500.98px) and (min-width: 1024.98px) {
//        &:hover {
//            background: rgba(133, 109, 101, 0.14);
//        }
//    }
//
//    @media(max-width: 480px) {
//        width: 100%;
//    }
//
//}
//
//input:active, input:focus {
//    border: 1px solid rgba(139, 154, 160, 1);
//}
//
//.form__row {
//    position: relative;
//}
//
//.form em.error {
//    font-size: 12px;
//    line-height: 18px;
//    position: absolute;
//    left: 26px;
//    display: inline-block;
//    width: 100%;
//    margin-top: 0;
//    top: 48px;
//    padding-left: 0;
//    color: #9C220C;
//    font-style: normal;
//    @media(max-width: 767px) {
//        top: 38px;
//        font-size: 11px;
//    }
//}
//
//.modal .form em.error {
//    top: 48px;
//}
//
//#thanks{
//    .modal__wrapper {
//        background-image: url(/../assets/images/popups/bg-login.jpg);
//        background-position: center;
//        background-repeat: no-repeat;
//        background-size: cover;
//        max-height: 260px;
//        @media(max-width: 767px) {
//            max-height: 220px;
//        }
//        .modal__title{
//            font-weight: bold;
//            font-size: 48px;
//            line-height: 24px;
//            letter-spacing: 0.04em;
//            color: #424242;
//            margin-top: 35px;
//            margin-bottom: 18px;
//
//            @media(max-width: 767px) {
//                margin-bottom: 10px;
//            }
//
//        }
//        p{
//            font-size: 18px;
//            line-height: 24px;
//            text-align: center;
//            letter-spacing: 0.05em;
//            color: #666666;
//            margin: 17px 0 30px;
//            padding: 0 50px;
//            @media(max-width: 767px) {
//                padding: 0 20px;
//            }
//        }
//    }
//}
//
//#login{
//    .modal__header {
//        padding: 85px 50px 0;
//        @media(max-width: 767px) {
//            padding: 25px 50px 0;
//        }
//    }
//    .modal__wrapper {
//        background-image: url(/../assets/images/popups/bg-login.jpg);
//        background-position: center;
//        background-repeat: no-repeat;
//        background-size: cover;
//        max-height: 442px;
//        @media(max-width: 767px) {
//            max-height: 330px;
//        }
//       .modal__title{
//            font-weight: 500;
//            font-size: 24px;
//            line-height: 24px;
//           letter-spacing: 2px;
//            color: #424242;
//           margin-bottom: 30px;
//           font-family: 'Intel', sans-serif;
//           @media(max-width: 767px) {
//               margin-bottom: 10px;
//           }
//
//        }
//    }
//     .main-form .button-send {
//        width: 174px;
//         margin-top: 35px;
//         @media(max-width: 767px) {
//             margin-top: 25px;
//         }
//    }
//    .form__bottom {
//        margin-top: 20px;
//    }
//}
//#modernization{
//    .modal__wrapper {
//        background-image: url(/../assets/images/popups/forgot.jpg);
//        background-position: center;
//        background-repeat: no-repeat;
//        background-size: cover;
//        max-height: 260px;
//        @media(max-width: 767px) {
//            max-height: 220px;
//        }
//        .modal__title{
//            font-weight: 500;
//            font-size: 24px;
//            line-height: 24px;
//            letter-spacing: 0.04em;
//            color: #424242;
//            margin-bottom: 17px;
//        }
//        p{
//            font-size: 18px;
//            line-height: 24px;
//            text-align: center;
//            letter-spacing: 0.05em;
//            color: #666666;
//            margin: 17px 0 30px;
//            padding: 0 50px;
//            @media(max-width: 767px) {
//                padding: 0 20px;
//            }
//        }
//    }
//}
//#forgot{
//    .modal__header {
//        padding: 86px 50px 0;
//        @media(max-width: 767px) {
//            padding: 25px 20px 0;
//        }
//    }
//    .modal__wrapper {
//        background-image: url(/../assets/images/popups/forgot.jpg);
//        background-position: center;
//        background-repeat: no-repeat;
//        background-size: cover;
//        max-height: 420px;
//        @media(max-width: 767px) {
//            max-height: 325px;
//        }
//        .modal__title{
//            font-weight: 500;
//            font-size: 24px;
//            line-height: 24px;
//            letter-spacing: 1.4px;
//            color: #424242;
//            margin-bottom: 17px;
//            font-family: 'Intel', sans-serif;
//        }
//        p{
//            font-size: 18px;
//            line-height: 24px;
//            text-align: center;
//            letter-spacing: 0.05em;
//            color: #666666;
//            margin: 17px 0 30px;
//            @media(max-width: 767px) {
//                font-size: 16px;
//            }
//        }
//    }
//    .main-form .button-send {
//        width: 197px;
//        margin-top: 48px;
//        @media(max-width: 767px) {
//            margin-top: 30px;
//        }
//    }
//}
//.form__bottom{
//    display: flex;
//    justify-content: space-between;
//    a{
//        font-size: 14px;
//        line-height: 24px;
//        letter-spacing: 0.04em;
//        color: #858585;
//    }
//}
//#wholesale{
//    .modal__text {
//        padding: 0 20px;
//    }
//}
//.form__row-checkbox {
//    margin-left: 26px;
//    font-size: 13px;
//    line-height: 18px;
//    color: #25282E;
//}
//.main-form__consent {
//    padding-left: 0;
//    span{
//        font-size: 14px;
//        line-height: 24px;
//        letter-spacing: 0.04em;
//        color: #858585;
//    }
//}
//.main-form__consent input {
//    position: absolute;
//    width: 0;
//    height: 0;
//    cursor: pointer;
//    opacity: 0;
//}
//.main-form__consent .checkmark {
//    position: absolute;
//    top: 4px;
//    left: -26px;
//    width: 16px;
//    height: 16px;
//    border: 1px solid #828283;
//    box-sizing: border-box;
//    border-radius: 3px;
//}
//.main-form__consent input:checked ~ .checkmark {
//    border: 1px solid #828283;
//    background: #828283;
//}
//.main-form__consent input:checked ~ .checkmark:before {
//    position: absolute;
//    top: 2px;
//    left: 2px;
//    width: 10px;
//    height: 9px;
//    content: '';
//    background-image: url(/../assets/images/popups/check.svg);
//    background-repeat: no-repeat;
//    background-size: 100%;
//    background-position: center;
//}
//
//
//
//@media (max-width: 2500.98px) and (min-width: 1199.98px) {
//    .main-form .button-send:hover {
//        background: rgba(133, 109, 101, 0.14);
//    }
//}
//
//modal .modal-bg {
//    display: block;
//    background: rgba(232, 235, 236, 0.8);
//    width: 100%;
//    z-index: -1;
//    position: fixed;
//    top: 0;
//    left: 0;
//    overflow: auto;
//    height: 100%;
//}
//
//.modal.active {
//    display: block;
//    width: 100%;
//    //height: 100vh;
//    z-index: 9999;
//    position: fixed;
//    top: 0;
//    left: 0;
//    overflow: auto;
//    height: 100%;
//}
//
//.modal .main-form__input-group {
//    margin-bottom: 15px;
//
//}
//
//.modal .form em.error {
//    top: 48px;
//}
//
//
//.modal__wrapper {
//    width: 665px;
//    position: absolute;
//    top: 50%;
//    left: 50%;
//    transform: translate(-50%, -50%);
//    padding: 0;
//    z-index: 1;
//    height: 100%;
//    overflow: auto;
//    max-height: 692px;
//    @media(max-width: 767px) {
//        max-height: 560px;
//    }
//}
//
//.modal__header {
//    margin-bottom: 0;
//}
//
//@media (max-width: 767.98px) {
//
//    .modal__wrapper {
//        width: 90%;
//    }
//    .modal__content {
//        width: 90%;
//    }
//    .modal .form em.error {
//        top: 38px;
//        width: 90%;
//    }
//}
//
//@media (max-width: 340.98px) {
//    .form__row-checkbox {
//        margin-left: 28px;
//    }
//}
